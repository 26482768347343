.placeMarker {
    display: flex;
    font-size: 14px;
    justify-content: center;
    position: relative;
    align-items: center;
    font-weight: 700;
    padding: 8px 8px;
    width: max-content;
    height: max-content;
    border-radius: 20px;
    border: 1px solid transparent;
    min-width: 30px;
}

.placeMarker:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translate(-50%, 0);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}

.filled {
    color: #000;
    background-color: #F8F8F8;
    border: 1px solid rgba(17, 25, 62, 1);
}

.filled:after {
    border-top: 10px solid rgba(17, 25, 62, 1);
}

.filled:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translate(-50%, 0);
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 8px solid #F8F8F8;
    z-index: 1;
}
/*//*/

.priority {
    color: #fff;
    background-color: #D9821C;
}

.priority:after {
    border-top: 10px solid #D9821C;
}

.available {
    color: #fff;
    background-color: #343E69;
}

.available:after {
    border-top: 10px solid #343E69;
}

.underTheOrder {
    color: rgba(17, 25, 62, 1);
    background-color: #F7E64D;
    border: 1px solid rgba(17, 25, 62, 1);
}

.underTheOrder:after {
    border-top: 10px solid rgba(17, 25, 62, 1);
}

.underTheOrder:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translate(-50%, 0);
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 8px solid #F7E64D;
    z-index: 1;
}

/*//*/

.placeMarker.active {
    color: #ffffff;
    background-color: #3F55BD;
    border: 1px solid rgba(17, 25, 62, 1);
}

.placeMarker.active:after {
    border-top: 10px solid rgba(17, 25, 62, 1);
}

.placeMarker.active:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translate(-50%, 0);
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 8px solid #3F55BD;
    z-index: 1;
}

.outline {
    color: rgba(17, 25, 62, 1);
    background-color: #fff;
    border: 1px solid rgba(17, 25, 62, 1);
}

.outline:after {
    border-top: 10px solid rgba(17, 25, 62, 1);
}

.outline:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translate(-50%, 0);
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 8px solid #ffffff;
    z-index: 1;
}
