@font-face {
  font-family: "HelveticaNeueCry";
  src: url("/assets/fonts/HelveticaNeueCyr-Bold.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "HelveticaNeueCry";
  src: url("/assets/fonts/HelveticaNeueCyr-Roman.woff") format("woff");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
