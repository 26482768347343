.slick-dots li.slick-active button:before {
  color: #3c56cb !important;
}

.slick-dots + li + button:hover:before,
.slick-dots + li + button:focus:before {
  opacity: 0.25;
}

.regular.slider {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.slick-arrow.slick-prev:before {
  content: "";
}

.slick-arrow.slick-next:before {
  content: "";
}

@media screen and (max-width: 1200px) {
  .article-slider {
    margin-bottom: 24px;
  }
}

@media screen and (min-width: 1200px) {
  .regular.article-slider {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
}
